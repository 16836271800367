var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import React from 'react';
import queryString from 'query-string';
import { Box, Button, CircularProgress, FormControl, Link as HyperLink, InputLabel, MenuItem, Select, Stack, Step, StepLabel, Stepper, TextField, } from '@mui/material';
import { useAnchorWallet } from '@solana/wallet-adapter-react';
import { Keypair, PublicKey, SystemProgram, SYSVAR_CLOCK_PUBKEY, SYSVAR_INSTRUCTIONS_PUBKEY, SYSVAR_RECENT_BLOCKHASHES_PUBKEY, SYSVAR_RENT_PUBKEY, Transaction, } from '@solana/web3.js';
import { AccountLayout, MintLayout, Token, TOKEN_PROGRAM_ID, } from '@solana/spl-token';
import { notify } from '../utils/notifications';
import BN from 'bn.js';
import * as bs58 from 'bs58';
import * as anchor from '@project-serum/anchor';
import { useWindowDimensions } from './AppBar';
import { CollapsePanel } from './CollapsePanel';
import { useConnection } from '../contexts/ConnectionContext';
import { CANDY_MACHINE_ID, GUMDROP_DISTRIBUTOR_ID, GUMDROP_TEMPORAL_SIGNER, SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID, TOKEN_METADATA_PROGRAM_ID, } from '../utils/ids';
import { getCandyMachine, getATA, getEdition, getEditionMarkerPda, getMetadata, } from '../utils/accounts';
import { MerkleTree } from '../utils/merkleTree';
import { explorerLinkFor, sendSignedTransaction } from '../utils/transactions';
export var chunk = function (arr, len) {
    var chunks = [];
    var n = arr.length;
    var i = 0;
    while (i < n) {
        chunks.push(arr.slice(i, (i += len)));
    }
    return chunks;
};
var walletKeyOrPda = function (walletKey, handle, pin, seed) { return __awaiter(void 0, void 0, void 0, function () {
    var key, seeds, _a, claimantPda;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!(pin === null)) return [3 /*break*/, 1];
                try {
                    key = new PublicKey(handle);
                    if (!key.equals(walletKey)) {
                        throw new Error('Claimant wallet handle does not match connected wallet');
                    }
                    return [2 /*return*/, [key, []]];
                }
                catch (err) {
                    throw new Error("Invalid claimant wallet handle ".concat(err));
                }
                return [3 /*break*/, 3];
            case 1:
                seeds = [
                    seed.toBuffer(),
                    Buffer.from(handle),
                    Buffer.from(pin.toArray('le', 4)),
                ];
                return [4 /*yield*/, PublicKey.findProgramAddress(__spreadArray(__spreadArray([seeds[0]], __read(chunk(seeds[1], 32)), false), [seeds[2]], false), GUMDROP_DISTRIBUTOR_ID)];
            case 2:
                _a = __read.apply(void 0, [_b.sent(), 1]), claimantPda = _a[0];
                return [2 /*return*/, [claimantPda, seeds]];
            case 3: return [2 /*return*/];
        }
    });
}); };
var buildMintClaim = function (program, walletKey, distributorKey, distributorInfo, tokenAcc, proof, handle, amount, index, pin) { return __awaiter(void 0, void 0, void 0, function () {
    var tokenAccKey, distTokenAccount, tokenAccountInfo, mint, _a, secret, pdaSeeds, leaf, matches, _b, claimStatus, cbump, setup, walletTokenKey, temporalSigner, claimAirdrop;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                try {
                    tokenAccKey = new PublicKey(tokenAcc);
                }
                catch (err) {
                    throw new Error("Invalid tokenAcc key ".concat(err));
                }
                return [4 /*yield*/, program.provider.connection.getAccountInfo(tokenAccKey)];
            case 1:
                distTokenAccount = _c.sent();
                if (distTokenAccount === null) {
                    throw new Error("Could not fetch distributor token account");
                }
                tokenAccountInfo = AccountLayout.decode(distTokenAccount.data);
                mint = new PublicKey(tokenAccountInfo.mint);
                console.log(mint.toBase58());
                return [4 /*yield*/, walletKeyOrPda(walletKey, handle, pin, mint)];
            case 2:
                _a = __read.apply(void 0, [_c.sent(), 2]), secret = _a[0], pdaSeeds = _a[1];
                leaf = Buffer.from(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read(new BN(index).toArray('le', 8)), false), __read(secret.toBuffer()), false), __read(mint.toBuffer()), false), __read(new BN(amount).toArray('le', 8)), false));
                matches = MerkleTree.verifyClaim(leaf, proof, Buffer.from(distributorInfo.root));
                if (!matches) {
                    throw new Error('Gumdrop merkle proof does not match');
                }
                return [4 /*yield*/, PublicKey.findProgramAddress([
                        Buffer.from('ClaimStatus'),
                        Buffer.from(new BN(index).toArray('le', 8)),
                        distributorKey.toBuffer(),
                    ], GUMDROP_DISTRIBUTOR_ID)];
            case 3:
                _b = __read.apply(void 0, [_c.sent(), 2]), claimStatus = _b[0], cbump = _b[1];
                setup = [];
                return [4 /*yield*/, getATA(walletKey, mint)];
            case 4:
                walletTokenKey = _c.sent();
                return [4 /*yield*/, program.provider.connection.getAccountInfo(walletTokenKey)];
            case 5:
                if ((_c.sent()) === null) {
                    setup.push(Token.createAssociatedTokenAccountInstruction(SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID, TOKEN_PROGRAM_ID, mint, walletTokenKey, walletKey, walletKey));
                }
                temporalSigner = distributorInfo.temporal.equals(PublicKey.default) ||
                    secret.equals(walletKey)
                    ? walletKey
                    : distributorInfo.temporal;
                return [4 /*yield*/, program.instruction.claim(cbump, new BN(index), new BN(amount), secret, proof, {
                        accounts: {
                            distributor: distributorKey,
                            claimStatus: claimStatus,
                            from: tokenAccKey,
                            to: walletTokenKey,
                            temporal: temporalSigner,
                            payer: walletKey,
                            systemProgram: SystemProgram.programId,
                            tokenProgram: TOKEN_PROGRAM_ID,
                        },
                    })];
            case 6:
                claimAirdrop = _c.sent();
                return [2 /*return*/, [{ setup: setup, claim: [claimAirdrop] }, pdaSeeds, []]];
        }
    });
}); };
var buildCandyClaim = function (program, candyProgram, walletKey, distributorKey, distributorInfo, tokenAcc, candyMachineStr, proof, handle, amount, index, pin) { return __awaiter(void 0, void 0, void 0, function () {
    var tokenAccKey, candyMachineKey, connection, candyMachine, whitelistMint, _a, secret, pdaSeeds, leaf, matches, _b, claimStatus, cbump, merkleClaim, temporalSigner, walletTokenKey, _c, _d, candyMachineMint, candyMachineMetadata, candyMachineMaster, _e, candyMachineCreatorKey, candyMachineCreatorBump, remainingAccounts, whitelistATA, tokenMintATA, candyMachineClaim, _f, _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                try {
                    tokenAccKey = new PublicKey(tokenAcc);
                }
                catch (err) {
                    throw new Error("Invalid tokenAcc key ".concat(err));
                }
                try {
                    candyMachineKey = new PublicKey(candyMachineStr);
                }
                catch (err) {
                    throw new Error("Invalid candy machine key ".concat(err));
                }
                connection = program.provider.connection;
                return [4 /*yield*/, getCandyMachine(connection, candyMachineKey)];
            case 1:
                candyMachine = _h.sent();
                console.log('Candy Machine', candyMachine);
                if (!candyMachine.data.whitelistMintSettings) {
                    // soft error?
                    throw new Error("Candy machine doesn't seem to have a whitelist mint. You can mint normally!");
                }
                whitelistMint = candyMachine.data.whitelistMintSettings.mint;
                return [4 /*yield*/, walletKeyOrPda(walletKey, handle, pin, whitelistMint)];
            case 2:
                _a = __read.apply(void 0, [_h.sent(), 2]), secret = _a[0], pdaSeeds = _a[1];
                leaf = Buffer.from(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read(new BN(index).toArray('le', 8)), false), __read(secret.toBuffer()), false), __read(whitelistMint.toBuffer()), false), __read(new BN(amount).toArray('le', 8)), false));
                matches = MerkleTree.verifyClaim(leaf, proof, Buffer.from(distributorInfo.root));
                if (!matches) {
                    throw new Error('Gumdrop merkle proof does not match');
                }
                return [4 /*yield*/, PublicKey.findProgramAddress([
                        Buffer.from('ClaimStatus'),
                        Buffer.from(new BN(index).toArray('le', 8)),
                        distributorKey.toBuffer(),
                    ], GUMDROP_DISTRIBUTOR_ID)];
            case 3:
                _b = __read.apply(void 0, [_h.sent(), 2]), claimStatus = _b[0], cbump = _b[1];
                merkleClaim = [];
                return [4 /*yield*/, connection.getAccountInfo(claimStatus)];
            case 4:
                if (!((_h.sent()) === null)) return [3 /*break*/, 8];
                temporalSigner = distributorInfo.temporal.equals(PublicKey.default) ||
                    secret.equals(walletKey)
                    ? walletKey
                    : distributorInfo.temporal;
                return [4 /*yield*/, getATA(walletKey, whitelistMint)];
            case 5:
                walletTokenKey = _h.sent();
                return [4 /*yield*/, connection.getAccountInfo(walletTokenKey)];
            case 6:
                if ((_h.sent()) === null) {
                    merkleClaim.push(Token.createAssociatedTokenAccountInstruction(SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID, TOKEN_PROGRAM_ID, candyMachine.data.whitelistMintSettings.mint, walletTokenKey, walletKey, walletKey));
                }
                _d = (_c = merkleClaim).push;
                return [4 /*yield*/, program.instruction.claim(cbump, new BN(index), new BN(amount), secret, proof, {
                        accounts: {
                            distributor: distributorKey,
                            claimStatus: claimStatus,
                            from: tokenAccKey,
                            to: walletTokenKey,
                            temporal: temporalSigner,
                            payer: walletKey,
                            systemProgram: SystemProgram.programId,
                            tokenProgram: TOKEN_PROGRAM_ID,
                        },
                    })];
            case 7:
                _d.apply(_c, [_h.sent()]);
                _h.label = 8;
            case 8:
                candyMachineMint = Keypair.generate();
                return [4 /*yield*/, getMetadata(candyMachineMint.publicKey)];
            case 9:
                candyMachineMetadata = _h.sent();
                return [4 /*yield*/, getEdition(candyMachineMint.publicKey)];
            case 10:
                candyMachineMaster = _h.sent();
                return [4 /*yield*/, PublicKey.findProgramAddress([Buffer.from('candy_machine'), candyMachineKey.toBuffer()], CANDY_MACHINE_ID)];
            case 11:
                _e = __read.apply(void 0, [_h.sent(), 2]), candyMachineCreatorKey = _e[0], candyMachineCreatorBump = _e[1];
                remainingAccounts = [];
                if (!candyMachine.data.whitelistMintSettings) return [3 /*break*/, 13];
                return [4 /*yield*/, getATA(walletKey, whitelistMint)];
            case 12:
                whitelistATA = _h.sent();
                remainingAccounts.push({
                    pubkey: whitelistATA,
                    isWritable: true,
                    isSigner: false,
                });
                if (candyMachine.data.whitelistMintSettings.mode.burnEveryTime) {
                    remainingAccounts.push({
                        pubkey: whitelistMint,
                        isWritable: true,
                        isSigner: false,
                    });
                    remainingAccounts.push({
                        pubkey: walletKey,
                        isWritable: false,
                        isSigner: true,
                    });
                }
                _h.label = 13;
            case 13:
                if (!candyMachine.tokenMint) return [3 /*break*/, 15];
                return [4 /*yield*/, getATA(walletKey, candyMachine.tokenMint)];
            case 14:
                tokenMintATA = _h.sent();
                remainingAccounts.push({
                    pubkey: tokenMintATA,
                    isWritable: true,
                    isSigner: false,
                });
                remainingAccounts.push({
                    pubkey: walletKey,
                    isWritable: false,
                    isSigner: true,
                });
                _h.label = 15;
            case 15:
                candyMachineClaim = [];
                return [4 /*yield*/, createMintAndAccount(connection, walletKey, candyMachineMint.publicKey, candyMachineClaim)];
            case 16:
                _h.sent();
                _g = (_f = candyMachineClaim).push;
                return [4 /*yield*/, candyProgram.instruction.mintNft(candyMachineCreatorBump, {
                        accounts: {
                            candyMachine: candyMachineKey,
                            candyMachineCreator: candyMachineCreatorKey,
                            payer: walletKey,
                            wallet: candyMachine.wallet,
                            metadata: candyMachineMetadata,
                            mint: candyMachineMint.publicKey,
                            mintAuthority: walletKey,
                            updateAuthority: walletKey,
                            masterEdition: candyMachineMaster,
                            tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
                            tokenProgram: TOKEN_PROGRAM_ID,
                            systemProgram: SystemProgram.programId,
                            rent: SYSVAR_RENT_PUBKEY,
                            clock: SYSVAR_CLOCK_PUBKEY,
                            recentBlockhashes: SYSVAR_RECENT_BLOCKHASHES_PUBKEY,
                            instructionSysvarAccount: SYSVAR_INSTRUCTIONS_PUBKEY,
                        },
                        remainingAccounts: remainingAccounts,
                    })];
            case 17:
                _g.apply(_f, [_h.sent()]);
                return [2 /*return*/, [
                        { setup: merkleClaim, claim: candyMachineClaim },
                        pdaSeeds,
                        [candyMachineMint],
                    ]];
        }
    });
}); };
var createMintAndAccount = function (connection, walletKey, mint, setup) { return __awaiter(void 0, void 0, void 0, function () {
    var walletTokenKey, _a, _b, _c, _d;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0: return [4 /*yield*/, getATA(walletKey, mint)];
            case 1:
                walletTokenKey = _f.sent();
                _b = (_a = setup).push;
                _d = (_c = SystemProgram).createAccount;
                _e = {
                    fromPubkey: walletKey,
                    newAccountPubkey: mint,
                    space: MintLayout.span
                };
                return [4 /*yield*/, connection.getMinimumBalanceForRentExemption(MintLayout.span)];
            case 2:
                _b.apply(_a, [_d.apply(_c, [(_e.lamports = _f.sent(),
                            _e.programId = TOKEN_PROGRAM_ID,
                            _e)])]);
                setup.push(Token.createInitMintInstruction(TOKEN_PROGRAM_ID, mint, 0, walletKey, walletKey));
                setup.push(Token.createAssociatedTokenAccountInstruction(SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID, TOKEN_PROGRAM_ID, mint, walletTokenKey, walletKey, walletKey));
                setup.push(Token.createMintToInstruction(TOKEN_PROGRAM_ID, mint, walletTokenKey, walletKey, [], 1));
                return [2 /*return*/];
        }
    });
}); };
var buildEditionClaim = function (program, walletKey, distributorKey, distributorInfo, masterMint, edition, proof, handle, amount, index, pin) { return __awaiter(void 0, void 0, void 0, function () {
    var masterMintKey, _a, secret, pdaSeeds, leaf, matches, _b, claimCount, cbump, temporalSigner, claimCountAccount, setup, newMint, newMetadataKey, masterMetadataKey, newEdition, masterEdition, distributorTokenKey, editionMarkKey, claim;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                try {
                    masterMintKey = new PublicKey(masterMint);
                }
                catch (err) {
                    throw new Error("Invalid master mint key ".concat(err));
                }
                return [4 /*yield*/, walletKeyOrPda(walletKey, handle, pin, masterMintKey)];
            case 1:
                _a = __read.apply(void 0, [_c.sent(), 2]), secret = _a[0], pdaSeeds = _a[1];
                leaf = Buffer.from(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], __read(new BN(index).toArray('le', 8)), false), __read(secret.toBuffer()), false), __read(masterMintKey.toBuffer()), false), __read(new BN(amount).toArray('le', 8)), false), __read(new BN(edition).toArray('le', 8)), false));
                matches = MerkleTree.verifyClaim(leaf, proof, Buffer.from(distributorInfo.root));
                if (!matches) {
                    throw new Error('Gumdrop merkle proof does not match');
                }
                return [4 /*yield*/, PublicKey.findProgramAddress([
                        Buffer.from('ClaimCount'),
                        Buffer.from(new BN(index).toArray('le', 8)),
                        distributorKey.toBuffer(),
                    ], GUMDROP_DISTRIBUTOR_ID)];
            case 2:
                _b = __read.apply(void 0, [_c.sent(), 2]), claimCount = _b[0], cbump = _b[1];
                temporalSigner = distributorInfo.temporal.equals(PublicKey.default) ||
                    secret.equals(walletKey)
                    ? walletKey
                    : distributorInfo.temporal;
                return [4 /*yield*/, program.provider.connection.getAccountInfo(claimCount)];
            case 3:
                claimCountAccount = _c.sent();
                if (claimCountAccount !== null) {
                    throw new Error("This edition was already claimed");
                }
                setup = [];
                newMint = Keypair.generate();
                return [4 /*yield*/, getMetadata(newMint.publicKey)];
            case 4:
                newMetadataKey = _c.sent();
                return [4 /*yield*/, getMetadata(masterMintKey)];
            case 5:
                masterMetadataKey = _c.sent();
                return [4 /*yield*/, getEdition(newMint.publicKey)];
            case 6:
                newEdition = _c.sent();
                return [4 /*yield*/, getEdition(masterMintKey)];
            case 7:
                masterEdition = _c.sent();
                return [4 /*yield*/, createMintAndAccount(program.provider.connection, walletKey, newMint.publicKey, setup)];
            case 8:
                _c.sent();
                return [4 /*yield*/, getATA(distributorKey, masterMintKey)];
            case 9:
                distributorTokenKey = _c.sent();
                return [4 /*yield*/, getEditionMarkerPda(masterMintKey, new BN(edition))];
            case 10:
                editionMarkKey = _c.sent();
                return [4 /*yield*/, program.instruction.claimEdition(cbump, new BN(index), new BN(amount), new BN(edition), secret, proof, {
                        accounts: {
                            distributor: distributorKey,
                            claimCount: claimCount,
                            temporal: temporalSigner,
                            payer: walletKey,
                            metadataNewMetadata: newMetadataKey,
                            metadataNewEdition: newEdition,
                            metadataMasterEdition: masterEdition,
                            metadataNewMint: newMint.publicKey,
                            metadataEditionMarkPda: editionMarkKey,
                            metadataNewMintAuthority: walletKey,
                            metadataMasterTokenAccount: distributorTokenKey,
                            metadataNewUpdateAuthority: walletKey,
                            metadataMasterMetadata: masterMetadataKey,
                            metadataMasterMint: masterMintKey,
                            systemProgram: SystemProgram.programId,
                            tokenProgram: TOKEN_PROGRAM_ID,
                            tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
                            rent: SYSVAR_RENT_PUBKEY,
                        },
                    })];
            case 11:
                claim = _c.sent();
                return [2 /*return*/, [{ setup: setup, claim: [claim] }, pdaSeeds, [newMint]]];
        }
    });
}); };
var fetchDistributor = function (program, distributorStr) { return __awaiter(void 0, void 0, void 0, function () {
    var key, info;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                try {
                    key = new PublicKey(distributorStr);
                }
                catch (err) {
                    throw new Error("Invalid distributor key ".concat(err));
                }
                return [4 /*yield*/, program.account.merkleDistributor.fetch(key)];
            case 1:
                info = _a.sent();
                return [2 /*return*/, [key, info]];
        }
    });
}); };
var fetchNeedsTemporalSigner = function (program, distributorStr, indexStr, claimMethod) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, key, info, _b, claimCount, claimCountAccount;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, fetchDistributor(program, distributorStr)];
            case 1:
                _a = __read.apply(void 0, [_c.sent(), 2]), key = _a[0], info = _a[1];
                if (!!info.temporal.equals(GUMDROP_TEMPORAL_SIGNER)) return [3 /*break*/, 2];
                // default pubkey or program itself (distribution through wallets)
                return [2 /*return*/, false];
            case 2:
                if (!(claimMethod === 'candy')) return [3 /*break*/, 5];
                return [4 /*yield*/, PublicKey.findProgramAddress([
                        Buffer.from('ClaimCount'),
                        Buffer.from(new BN(Number(indexStr)).toArray('le', 8)),
                        key.toBuffer(),
                    ], GUMDROP_DISTRIBUTOR_ID)];
            case 3:
                _b = __read.apply(void 0, [_c.sent(), 1]), claimCount = _b[0];
                return [4 /*yield*/, program.provider.connection.getAccountInfo(claimCount)];
            case 4:
                claimCountAccount = _c.sent();
                return [2 /*return*/, claimCountAccount === null];
            case 5: 
            // default to need one
            return [2 /*return*/, true];
        }
    });
}); };
export var Claim = function (props) {
    var connection = useConnection();
    var wallet = useAnchorWallet();
    var _a = __read(React.useState(null), 2), program = _a[0], setProgram = _a[1];
    React.useEffect(function () {
        if (!wallet) {
            return;
        }
        var wrap = function () { return __awaiter(void 0, void 0, void 0, function () {
            var provider, _a, gumdropIdl, candyIdl, err_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        provider = new anchor.Provider(connection, wallet, {
                            preflightCommitment: 'recent',
                        });
                        return [4 /*yield*/, Promise.all([
                                anchor.Program.fetchIdl(GUMDROP_DISTRIBUTOR_ID, provider),
                                anchor.Program.fetchIdl(CANDY_MACHINE_ID, provider),
                            ])];
                    case 1:
                        _a = __read.apply(void 0, [_b.sent(), 2]), gumdropIdl = _a[0], candyIdl = _a[1];
                        if (!gumdropIdl)
                            throw new Error('Failed to fetch gumdrop IDL');
                        if (!candyIdl)
                            throw new Error('Failed to fetch candy machine IDL');
                        setProgram({
                            gumdrop: new anchor.Program(gumdropIdl, GUMDROP_DISTRIBUTOR_ID, provider),
                            candyMachine: new anchor.Program(candyIdl, CANDY_MACHINE_ID, provider),
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _b.sent();
                        console.error('Failed to fetch IDL', err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        wrap();
    }, [wallet]);
    var query = props.location.search;
    if (query && query.length > 0) {
        localStorage.setItem('claimQuery', query);
    }
    else {
        var stored = localStorage.getItem('claimQuery');
        if (stored)
            query = stored;
    }
    var params = queryString.parse(query);
    var _b = __read(React.useState(params.distributor || ''), 2), distributor = _b[0], setDistributor = _b[1];
    var _c = __read(React.useState(params.candy
        ? 'candy'
        : params.tokenAcc
            ? 'transfer'
            : params.master
                ? 'edition'
                : ''), 2), claimMethod = _c[0], setClaimMethod = _c[1];
    var _d = __read(React.useState(params.tokenAcc || ''), 2), tokenAcc = _d[0], setTokenAcc = _d[1];
    var _e = __read(React.useState(params.candy || ''), 2), candyMachine = _e[0], setCandyMachine = _e[1];
    var _f = __read(React.useState(params.master || ''), 2), masterMint = _f[0], setMasterMint = _f[1];
    var _g = __read(React.useState(params.edition || ''), 2), editionStr = _g[0], setEditionStr = _g[1];
    var _h = __read(React.useState(params.handle || ''), 2), handle = _h[0], setHandle = _h[1];
    var _j = __read(React.useState(params.amount || ''), 2), amountStr = _j[0], setAmount = _j[1];
    var _k = __read(React.useState(params.index || ''), 2), indexStr = _k[0], setIndex = _k[1];
    var _l = __read(React.useState(params.pin || ''), 2), pinStr = _l[0], setPin = _l[1];
    var _m = __read(React.useState(params.proof || ''), 2), proofStr = _m[0], setProof = _m[1];
    var _o = __read(React.useState(params.method || 'aws-email'), 2), commMethod = _o[0], setCommMethod = _o[1];
    var allFieldsPopulated = distributor.length > 0 &&
        (claimMethod === 'transfer'
            ? tokenAcc.length > 0
            : claimMethod === 'candy'
                ? tokenAcc.length > 0 && candyMachine.length > 0
                : claimMethod === 'edition'
                    ? masterMint.length > 0 && editionStr.length > 0
                    : false) &&
        handle.length > 0 &&
        amountStr.length > 0 &&
        indexStr.length > 0;
    // NB: pin can be empty if handle is a public-key and we are claiming through wallets
    // NB: proof can be empty!
    var _p = __read(React.useState(!allFieldsPopulated), 2), editable = _p[0], setEditable = _p[1];
    // temporal verification
    var _q = __read(React.useState(null), 2), transaction = _q[0], setTransaction = _q[1];
    var _r = __read(React.useState(''), 2), OTPStr = _r[0], setOTPStr = _r[1];
    // async computed
    var _s = __read(React.useState(true), 2), asyncNeedsTemporalSigner = _s[0], setNeedsTemporalSigner = _s[1];
    React.useEffect(function () {
        var wrap = function () { return __awaiter(void 0, void 0, void 0, function () {
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 2, , 3]);
                        if (!program)
                            return [2 /*return*/];
                        _a = setNeedsTemporalSigner;
                        return [4 /*yield*/, fetchNeedsTemporalSigner(program.gumdrop, distributor, indexStr, claimMethod)];
                    case 1:
                        _a.apply(void 0, [_c.sent()]);
                        return [3 /*break*/, 3];
                    case 2:
                        _b = _c.sent();
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        wrap();
    }, [program, distributor, indexStr, claimMethod]);
    var lambdaAPIEndpoint = "https://".concat(process.env.LAMBDA_GATEWAY_API_ID, ".execute-api.us-east-2.amazonaws.com/send-OTP");
    var skipAWSWorkflow = false;
    var sendOTP = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var index, amount, pin, _a, distributorKey, distributorInfo, proof, instructions, pdaSeeds, extraSigners, edition, signersOf, partialSignExtra, recentBlockhash, setupTx, setupInstrs, setupSigners, claimTx, claimInstrs, claimSigners, txnNeedsTemporalSigner, otpQuery, params_1, response, data, _b, succeeded, toCheck;
        var _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    e.preventDefault();
                    if (!wallet || !program) {
                        throw new Error("Wallet not connected");
                    }
                    index = Number(indexStr);
                    amount = Number(amountStr);
                    pin = null;
                    if (isNaN(amount)) {
                        throw new Error("Could not parse amount ".concat(amountStr));
                    }
                    if (isNaN(index)) {
                        throw new Error("Could not parse index ".concat(indexStr));
                    }
                    if (params.pin !== 'NA') {
                        try {
                            pin = new BN(pinStr);
                        }
                        catch (err) {
                            throw new Error("Could not parse pin ".concat(pinStr, ": ").concat(err));
                        }
                    }
                    return [4 /*yield*/, fetchDistributor(program.gumdrop, distributor)];
                case 1:
                    _a = __read.apply(void 0, [_f.sent(), 2]), distributorKey = _a[0], distributorInfo = _a[1];
                    console.log('Distributor', distributorInfo);
                    proof = proofStr === ''
                        ? []
                        : proofStr.split(',').map(function (b) {
                            var ret = Buffer.from(bs58.decode(b));
                            if (ret.length !== 32)
                                throw new Error("Invalid proof hash length");
                            return ret;
                        });
                    if (!(claimMethod === 'candy')) return [3 /*break*/, 3];
                    console.log('Building candy claim');
                    return [4 /*yield*/, buildCandyClaim(program.gumdrop, program.candyMachine, wallet.publicKey, distributorKey, distributorInfo, tokenAcc, candyMachine, proof, handle, amount, index, pin)];
                case 2:
                    _c = __read.apply(void 0, [_f.sent(), 3]), instructions = _c[0], pdaSeeds = _c[1], extraSigners = _c[2];
                    return [3 /*break*/, 8];
                case 3:
                    if (!(claimMethod === 'transfer')) return [3 /*break*/, 5];
                    return [4 /*yield*/, buildMintClaim(program.gumdrop, wallet.publicKey, distributorKey, distributorInfo, tokenAcc, proof, handle, amount, index, pin)];
                case 4:
                    _d = __read.apply(void 0, [_f.sent(), 3]), instructions = _d[0], pdaSeeds = _d[1], extraSigners = _d[2];
                    return [3 /*break*/, 8];
                case 5:
                    if (!(claimMethod === 'edition')) return [3 /*break*/, 7];
                    edition = Number(editionStr);
                    if (isNaN(edition)) {
                        throw new Error("Could not parse edition ".concat(editionStr));
                    }
                    return [4 /*yield*/, buildEditionClaim(program.gumdrop, wallet.publicKey, distributorKey, distributorInfo, masterMint, edition, proof, handle, amount, index, pin)];
                case 6:
                    _e = __read.apply(void 0, [_f.sent(), 3]), instructions = _e[0], pdaSeeds = _e[1], extraSigners = _e[2];
                    return [3 /*break*/, 8];
                case 7: throw new Error("Unknown claim method ".concat(claimMethod));
                case 8:
                    // NB: if we're claiming through wallets then pdaSeeds should be empty
                    // since the secret is the wallet key (which is also a signer)
                    if (pin === null && pdaSeeds.length > 0) {
                        throw new Error("Internal error: PDA generated when distributing to wallet directly");
                    }
                    signersOf = function (instrs) {
                        var e_1, _a, e_2, _b;
                        var signers = new Set();
                        try {
                            for (var instrs_1 = __values(instrs), instrs_1_1 = instrs_1.next(); !instrs_1_1.done; instrs_1_1 = instrs_1.next()) {
                                var instr = instrs_1_1.value;
                                try {
                                    for (var _c = (e_2 = void 0, __values(instr.keys)), _d = _c.next(); !_d.done; _d = _c.next()) {
                                        var key = _d.value;
                                        if (key.isSigner)
                                            signers.add(key.pubkey);
                                    }
                                }
                                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                                finally {
                                    try {
                                        if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                                    }
                                    finally { if (e_2) throw e_2.error; }
                                }
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (instrs_1_1 && !instrs_1_1.done && (_a = instrs_1.return)) _a.call(instrs_1);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        return __spreadArray([], __read(signers), false);
                    };
                    partialSignExtra = function (tx, expected) {
                        var matching = extraSigners.filter(function (kp) {
                            return expected.find(function (p) { return p.equals(kp.publicKey); });
                        });
                        if (matching.length > 0) {
                            tx.partialSign.apply(tx, __spreadArray([], __read(matching), false));
                        }
                    };
                    return [4 /*yield*/, connection.getRecentBlockhash('singleGossip')];
                case 9:
                    recentBlockhash = (_f.sent()).blockhash;
                    setupTx = null;
                    if (instructions.setup !== null && instructions.setup.length !== 0) {
                        setupTx = new Transaction({
                            feePayer: wallet.publicKey,
                            recentBlockhash: recentBlockhash,
                        });
                        setupInstrs = instructions.setup;
                        setupSigners = signersOf(setupInstrs);
                        console.log("Expecting the following setup signers: ".concat(setupSigners.map(function (s) {
                            return s.toBase58();
                        })));
                        setupTx.add.apply(setupTx, __spreadArray([], __read(setupInstrs), false));
                        setupTx.setSigners.apply(setupTx, __spreadArray([], __read(setupSigners), false));
                        partialSignExtra(setupTx, setupSigners);
                    }
                    claimTx = new Transaction({
                        feePayer: wallet.publicKey,
                        recentBlockhash: recentBlockhash,
                    });
                    claimInstrs = instructions.claim;
                    claimSigners = signersOf(claimInstrs);
                    console.log("Expecting the following claim signers: ".concat(claimSigners.map(function (s) {
                        return s.toBase58();
                    })));
                    claimTx.add.apply(claimTx, __spreadArray([], __read(claimInstrs), false));
                    claimTx.setSigners.apply(claimTx, __spreadArray([], __read(claimSigners), false));
                    partialSignExtra(claimTx, claimSigners);
                    txnNeedsTemporalSigner = claimTx.signatures.some(function (s) {
                        return s.publicKey.equals(GUMDROP_TEMPORAL_SIGNER);
                    })
                        ? claimTx
                        : setupTx &&
                            setupTx.signatures.some(function (s) {
                                return s.publicKey.equals(GUMDROP_TEMPORAL_SIGNER);
                            })
                            ? setupTx
                            : /*otherwise*/ null;
                    if (!(txnNeedsTemporalSigner !== null && !skipAWSWorkflow)) return [3 /*break*/, 15];
                    otpQuery = {
                        method: 'send',
                        transaction: bs58.encode(txnNeedsTemporalSigner.serializeMessage()),
                        seeds: pdaSeeds,
                        comm: commMethod,
                    };
                    params_1 = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(otpQuery),
                    };
                    return [4 /*yield*/, fetch(lambdaAPIEndpoint, params_1)];
                case 10:
                    response = _f.sent();
                    console.log(response);
                    if (response.status !== 200) {
                        throw new Error("Failed to send AWS OTP");
                    }
                    data = void 0;
                    _f.label = 11;
                case 11:
                    _f.trys.push([11, 13, , 14]);
                    return [4 /*yield*/, response.json()];
                case 12:
                    data = _f.sent();
                    return [3 /*break*/, 14];
                case 13:
                    _b = _f.sent();
                    throw new Error("Could not parse AWS OTP response");
                case 14:
                    console.log('AWS OTP response data:', data);
                    succeeded = void 0, toCheck = void 0;
                    switch (commMethod) {
                        case 'discord': {
                            succeeded = !!data.id;
                            toCheck = 'discord';
                            break;
                        }
                        case 'aws-email': {
                            succeeded = !!data.MessageId;
                            toCheck = 'email';
                            break;
                        }
                        case 'aws-sms': {
                            succeeded = !!data.MessageId;
                            toCheck = 'SMS';
                            break;
                        }
                    }
                    if (!succeeded) {
                        throw new Error("Failed to send AWS OTP");
                    }
                    notify({
                        message: 'OTP sent',
                        description: "Please check your ".concat(toCheck, " (").concat(handle, ") for an OTP"),
                    });
                    _f.label = 15;
                case 15: return [2 /*return*/, {
                        setup: setupTx,
                        claim: claimTx,
                    }];
            }
        });
    }); };
    var verifyOTP = function (e, transaction) { return __awaiter(void 0, void 0, void 0, function () {
        var claimTx, setupTx, txnNeedsTemporalSigner, OTP, params_2, response, blob, data, _a, sig, fullySigned, _b, idx, tx, result, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    e.preventDefault();
                    if (!transaction) {
                        throw new Error("Transaction not available for OTP verification");
                    }
                    if (!wallet || !program) {
                        throw new Error("Wallet not connected");
                    }
                    claimTx = transaction.claim;
                    setupTx = transaction.setup;
                    txnNeedsTemporalSigner = claimTx.signatures.some(function (s) {
                        return s.publicKey.equals(GUMDROP_TEMPORAL_SIGNER);
                    })
                        ? claimTx
                        : setupTx &&
                            setupTx.signatures.some(function (s) {
                                return s.publicKey.equals(GUMDROP_TEMPORAL_SIGNER);
                            })
                            ? setupTx
                            : /*otherwise*/ null;
                    if (!(txnNeedsTemporalSigner && !skipAWSWorkflow)) return [3 /*break*/, 6];
                    OTP = Number(OTPStr);
                    if (isNaN(OTP) || OTPStr.length === 0) {
                        throw new Error("Could not parse OTP ".concat(OTPStr));
                    }
                    params_2 = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        FunctionName: 'send-OTP',
                        body: JSON.stringify({
                            method: 'verify',
                            otp: OTP,
                            handle: handle, // TODO?
                        }),
                    };
                    return [4 /*yield*/, fetch(lambdaAPIEndpoint, params_2)];
                case 1:
                    response = _e.sent();
                    console.log(response);
                    if (response.status !== 200) {
                        blob = JSON.stringify(response);
                        throw new Error("Failed to verify AWS OTP. ".concat(blob));
                    }
                    data = void 0;
                    _e.label = 2;
                case 2:
                    _e.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, response.json()];
                case 3:
                    data = _e.sent();
                    return [3 /*break*/, 5];
                case 4:
                    _a = _e.sent();
                    throw new Error("Could not parse AWS OTP verification response");
                case 5:
                    console.log('AWS verify response data:', data);
                    sig = void 0;
                    try {
                        sig = bs58.decode(data);
                    }
                    catch (_f) {
                        throw new Error("Could not decode transaction signature ".concat(data.body));
                    }
                    txnNeedsTemporalSigner.addSignature(GUMDROP_TEMPORAL_SIGNER, sig);
                    _e.label = 6;
                case 6:
                    _e.trys.push([6, 8, , 9]);
                    return [4 /*yield*/, wallet.signAllTransactions(transaction.setup === null
                            ? [transaction.claim]
                            : [transaction.setup, transaction.claim])];
                case 7:
                    fullySigned = _e.sent();
                    return [3 /*break*/, 9];
                case 8:
                    _b = _e.sent();
                    throw new Error('Failed to sign transaction');
                case 9:
                    idx = 0;
                    _e.label = 10;
                case 10:
                    if (!(idx < fullySigned.length)) return [3 /*break*/, 13];
                    tx = fullySigned[idx];
                    return [4 /*yield*/, sendSignedTransaction({
                            connection: connection,
                            signedTransaction: tx,
                        })];
                case 11:
                    result = _e.sent();
                    console.log(result);
                    notify({
                        message: "Claim succeeded: ".concat(idx + 1, " of ").concat(fullySigned.length),
                        description: (React.createElement(HyperLink, { href: explorerLinkFor(result.txid, connection) }, "View transaction on explorer")),
                    });
                    _e.label = 12;
                case 12:
                    ++idx;
                    return [3 /*break*/, 10];
                case 13:
                    setTransaction(null);
                    _e.label = 14;
                case 14:
                    _e.trys.push([14, 16, , 17]);
                    _c = setNeedsTemporalSigner;
                    return [4 /*yield*/, fetchNeedsTemporalSigner(program.gumdrop, distributor, indexStr, claimMethod)];
                case 15:
                    _c.apply(void 0, [_e.sent()]);
                    return [3 /*break*/, 17];
                case 16:
                    _d = _e.sent();
                    return [3 /*break*/, 17];
                case 17: return [2 /*return*/];
            }
        });
    }); };
    var _t = __read(React.useState(false), 2), loading = _t[0], setLoading = _t[1];
    var loadingProgress = function () { return (React.createElement(CircularProgress, { size: 24, sx: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
        } })); };
    var verifyOTPC = function (onClick) { return (React.createElement(React.Fragment, null,
        React.createElement(TextField, { id: "otp-text-field", label: "OTP", value: OTPStr, onChange: function (e) { return setOTPStr(e.target.value); } }),
        React.createElement(Box, null),
        React.createElement(Box, { sx: { position: 'relative' } },
            React.createElement(Button, { disabled: !wallet || !program || !OTPStr || loading, variant: "contained", color: "secondary", style: { width: '100%' }, onClick: function (e) {
                    setLoading(true);
                    var wrap = function () { return __awaiter(void 0, void 0, void 0, function () {
                        var err_2;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 2, , 3]);
                                    return [4 /*yield*/, verifyOTP(e, transaction)];
                                case 1:
                                    _a.sent();
                                    setLoading(false);
                                    onClick();
                                    return [3 /*break*/, 3];
                                case 2:
                                    err_2 = _a.sent();
                                    console.error(err_2);
                                    notify({
                                        message: 'Claim failed',
                                        description: "".concat(err_2),
                                    });
                                    setLoading(false);
                                    return [3 /*break*/, 3];
                                case 3: return [2 /*return*/];
                            }
                        });
                    }); };
                    wrap();
                } }, "Claim Gumdrop"),
            loading && loadingProgress()))); };
    var claimData = function (claimMethod) {
        if (claimMethod === 'candy') {
            return (React.createElement(React.Fragment, null,
                React.createElement(TextField, { id: "token-acc-text-field", label: "Whitelist Token Account", value: tokenAcc, onChange: function (e) { return setTokenAcc(e.target.value); }, disabled: !editable }),
                React.createElement(TextField, { id: "candy-text-field", label: "Candy Machine", value: candyMachine, onChange: function (e) { return setCandyMachine(e.target.value); }, disabled: !editable })));
        }
        else if (claimMethod === 'transfer') {
            return (React.createElement(React.Fragment, null,
                React.createElement(TextField, { id: "token-acc-text-field", label: "Source Token Account", value: tokenAcc, onChange: function (e) { return setTokenAcc(e.target.value); }, disabled: !editable })));
        }
        else if (claimMethod === 'edition') {
            return (React.createElement(React.Fragment, null,
                React.createElement(TextField, { id: "master-mint-text-field", label: "Master Mint", value: masterMint, onChange: function (e) { return setMasterMint(e.target.value); }, disabled: !editable }),
                React.createElement(TextField, { id: "edition-text-field", label: "Edition", value: editionStr, onChange: function (e) { return setEditionStr(e.target.value); }, disabled: !editable })));
        }
    };
    var populateClaimC = function (onClick) { return (React.createElement(React.Fragment, null,
        React.createElement(Box, null),
        React.createElement(FormControl, { fullWidth: true },
            React.createElement(InputLabel, { id: "claim-method-label", disabled: !editable }, "Gumdrop Type"),
            React.createElement(Select, { labelId: "claim-method-label", id: "claim-method-select", value: claimMethod, label: "Claim Method", onChange: function (e) {
                    setClaimMethod(e.target.value);
                }, style: { textAlign: 'left' }, disabled: !editable },
                React.createElement(MenuItem, { value: 'transfer' }, "Token Transfer"),
                React.createElement(MenuItem, { value: 'candy' }, "Candy Machine"),
                React.createElement(MenuItem, { value: 'edition' }, "Limited Edition"))),
        React.createElement(TextField, { id: "distributor-text-field", label: "Gumdrop Address", value: distributor, onChange: function (e) { return setDistributor(e.target.value); }, disabled: !editable }),
        React.createElement(TextField, { id: "handle-text-field", label: "Handle", value: handle, onChange: function (e) { return setHandle(e.target.value); }, disabled: !editable }),
        claimMethod !== 'edition' && (React.createElement(TextField, { id: "amount-text-field", label: "Amount", value: amountStr, onChange: function (e) { return setAmount(e.target.value); }, disabled: !editable })),
        claimMethod !== '' && claimData(claimMethod),
        React.createElement(CollapsePanel, { id: "additional-parameters", panelName: "Additional Parameters" },
            React.createElement(Stack, { spacing: 2 },
                React.createElement(FormControl, { fullWidth: true },
                    React.createElement(InputLabel, { id: "comm-method-label", disabled: !editable }, "Distribution Method"),
                    React.createElement(Select, { labelId: "comm-method-label", id: "comm-method-select", value: commMethod, label: "Distribution Method", onChange: function (e) {
                            if (e.target.value === 'discord') {
                                notify({
                                    message: 'Discord distribution unavailable',
                                    description: 'Please use the CLI for this. Discord does not support browser-connection requests',
                                });
                                return;
                            }
                            localStorage.setItem('commMethod', e.target.value);
                            setCommMethod(e.target.value);
                        }, style: { textAlign: 'left' }, disabled: !editable },
                        React.createElement(MenuItem, { value: 'aws-email' }, "AWS Email"),
                        React.createElement(MenuItem, { value: 'aws-sms' }, "AWS SMS"),
                        React.createElement(MenuItem, { value: 'discord' }, "Discord"),
                        React.createElement(MenuItem, { value: 'wallets' }, "Wallets"),
                        React.createElement(MenuItem, { value: 'manual' }, "Manual"))),
                React.createElement(TextField, { id: "index-text-field", label: "Index", value: indexStr, onChange: function (e) { return setIndex(e.target.value); }, disabled: !editable }),
                params.pin !== 'NA' && (React.createElement(TextField, { id: "pin-text-field", label: "Pin", value: pinStr, onChange: function (e) { return setPin(e.target.value); }, disabled: !editable })),
                React.createElement(TextField, { id: "proof-text-field", label: "Proof", multiline: true, value: proofStr, onChange: function (e) { return setProof(e.target.value); }, disabled: !editable }),
                React.createElement(Button, { onClick: function () { return setEditable(!editable); } }, !editable ? 'Edit Claim' : 'Stop Editing'))),
        React.createElement(Box, null),
        React.createElement(Box, { sx: { position: 'relative' } },
            React.createElement(Button, { disabled: !wallet || !program || !allFieldsPopulated || loading, variant: "contained", style: { width: '100%' }, color: asyncNeedsTemporalSigner ? 'primary' : 'secondary', onClick: function (e) {
                    setLoading(true);
                    var wrap = function () { return __awaiter(void 0, void 0, void 0, function () {
                        var needsTemporalSigner, transaction_1, err_3;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    _a.trys.push([0, 6, , 7]);
                                    if (!program) {
                                        throw new Error("Internal error: no program loaded for claim");
                                    }
                                    return [4 /*yield*/, fetchNeedsTemporalSigner(program.gumdrop, distributor, indexStr, claimMethod)];
                                case 1:
                                    needsTemporalSigner = _a.sent();
                                    return [4 /*yield*/, sendOTP(e)];
                                case 2:
                                    transaction_1 = _a.sent();
                                    if (!!needsTemporalSigner) return [3 /*break*/, 4];
                                    return [4 /*yield*/, verifyOTP(e, transaction_1)];
                                case 3:
                                    _a.sent();
                                    return [3 /*break*/, 5];
                                case 4:
                                    setTransaction(transaction_1);
                                    _a.label = 5;
                                case 5:
                                    setLoading(false);
                                    onClick();
                                    return [3 /*break*/, 7];
                                case 6:
                                    err_3 = _a.sent();
                                    console.error(err_3);
                                    notify({
                                        message: 'Claim failed',
                                        description: "".concat(err_3),
                                    });
                                    setLoading(false);
                                    return [3 /*break*/, 7];
                                case 7: return [2 /*return*/];
                            }
                        });
                    }); };
                    wrap();
                } }, asyncNeedsTemporalSigner ? 'Next' : 'Claim Gumdrop'),
            loading && loadingProgress()))); };
    var steps = [{ name: 'Populate Claim', inner: populateClaimC }];
    if (asyncNeedsTemporalSigner) {
        steps.push({ name: 'Verify OTP', inner: verifyOTPC });
    }
    // TODO: better interaction between setting `asyncNeedsTemporalSigner` and
    // the stepper... this is pretty jank
    var _u = __read(React.useState(0), 2), activeStep = _u[0], setActiveStep = _u[1];
    var stepToUse = Math.min(activeStep, steps.length - 1);
    var handleNext = function () {
        // return to start if going past the end (claim succeeded)
        setActiveStep(function (prev) {
            if (prev === steps.length - 1) {
                return 0;
            }
            else {
                return prev + 1;
            }
        });
    };
    var handleBack = function () {
        setActiveStep(function (prev) { return prev - 1; });
    };
    var stepper = (React.createElement(React.Fragment, null,
        React.createElement(Stepper, { activeStep: stepToUse }, steps.map(function (s) {
            return (React.createElement(Step, { key: s.name },
                React.createElement(StepLabel, null, s.name)));
        }))));
    var maxWidth = 960;
    var width = useWindowDimensions().width;
    return (React.createElement(Stack, { spacing: 2, style: {
            margin: 'auto',
            maxWidth: Math.min(width, maxWidth),
        } },
        asyncNeedsTemporalSigner && stepper,
        steps[stepToUse].inner(handleNext),
        stepToUse > 0 && React.createElement(Button, { onClick: handleBack }, "Back")));
};
